import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const PageDurationTracker = () => {
    const startTimeRef = useRef(null);
    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState(location);
    const locationRef = useRef(location);

    useEffect(() => {
        if (locationRef?.current?.pathname !== location?.pathname) {
            setPrevLocation(locationRef.current);
        }
        // Record the start time when the location changes
        if (startTimeRef.current) {
            const endTime = new Date().getTime();
            const duration = (endTime - startTimeRef.current) / 1000; // duration in seconds

            if (duration > 1) {
                // Log or send the duration to a server or analytics service
                console.log(`User spent ${duration} seconds on the previous page`);
                // console.log(location?.pathname)
                console.log(prevLocation?.pathname)
                console.log(JSON.stringify({
                    category: "time_on_page",
                    action: JSON.stringify({
                        duration: duration,
                        name: prevLocation?.pathname
                    })
                }))

                // Example: Send data to an analytics service
                ReactGA.event("time_on_page", {
                    category: "time_on_page",
                    action: JSON.stringify({
                        duration: duration,
                        name: prevLocation?.pathname
                    })
                });
            }
        }

        // Set the start time for the new location
        startTimeRef.current = new Date().getTime();
        locationRef.current = location;
        // eslint-disable-next-line
    }, [location]);

    return null;
};

export default PageDurationTracker;
